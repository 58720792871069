import {useRef, useState, MutableRefObject} from 'react';

export default function useStateRef<Type>(initial: Type): [
  Type,
  MutableRefObject<Type>,
  (arg: Type) => void,
] {
  const [value, _setValue] = useState<Type>(initial);
  const ref = useRef<Type>(initial);

  function setValue(update: Type): void {
    _setValue(update);
    ref.current = update;
  }

  return [value, ref, setValue];
}
