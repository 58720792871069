// export const CUSTOM_POSE_LANDMARKS = [
//   0, 2, 5, 7, 8, 9, 10, 11, 12,
//   13, 14, 15, 16, 19, 20, 23,
//   24, 25, 26, 27, 28, 31, 32,
// ];

// export const CUSTOM_POSE_CONNECTIONS: [number, number][] = [
//   [ 0,  2],
//   [ 0,  5],
//   [ 2,  7],
//   [ 5,  8],
//   [ 9, 10],
//   [11, 12],
//   [11, 13],
//   [11, 23],
//   [12, 14],
//   [12, 24],
//   [13, 15],
//   [14, 16],
//   [15, 19],
//   [16, 20],
//   [23, 24],
//   [23, 25],
//   [24, 26],
//   [25, 27],
//   [26, 28],
//   [27, 31],
//   [28, 32],
// ];

export const CUSTOM_POSE_LANDMARKS = [
  0, 11, 12, 13, 14, 15, 16, 19, 20,
];

export const CUSTOM_POSE_CONNECTIONS: [number, number][] = [
  [11, 12],
  [11, 13],
  [12, 14],
  [13, 15],
  [14, 16],
  [15, 19],
  [16, 20],
];
