import {useRef, useState} from 'react';

interface UseCountdownType {
  counter: number;
  start: (callback: () => void) => void;
}

function useCountdown(initial: number): UseCountdownType {
  const [counter, setCounter] = useState(0);
  const timer = useRef<number | null>(null);

  function start(callback: () => void) {
    clearInterval(timer.current ?? undefined);
    setCounter(initial);

    timer.current = window.setInterval(() => {
      setCounter(state => {
        if (state <= 1) {
          clearInterval(timer.current ?? undefined);
          callback();
        }

        return state - 1 >= 0 ? state -1 : state;
      })
    }, 1000);
  }

  return {counter, start};
}

export default useCountdown;
